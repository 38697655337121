$primary: #FFFFFF;
$secondary: #383838;
$tertiary: #384050;
$accent: #d4d1e5;

html, body {
    margin:0 auto;
    background-color: rgb(245, 250, 250);
}

h1, h2, h3, title, caption, th {
    font-family:'Montserrat', 'Arial';
    color:$secondary;
}

p, span, td, button, select, label{
    font-family:'Source Sans Pro', Arial
}

span {
    color:$primary
}

.container {
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    @media (min-width: 975px)
    {
        min-height: 100vh;
        max-height: 100vh;
    }
    @media (max-width: 975px)
    {
        overflow-x: hidden;
        align-items: center;
    }
    //align-items: stretch;
    //align-content: normal;
}