$primary: #FFFFFF;
$secondary: #383838;
$tertiary: #384050;
$accent: #d4d1e5;

#adviceDesktop {
    @media (max-width: 500px)
    {
        display: none;
    }
    margin: 0 auto;
    margin-top: 5px;
    color: $secondary;
    font-size: 16px;
    font-weight: 600;
}

#adviceMobile {
    @media (min-width: 500px)
    {
        display: none;
    }
    margin-top: 4px;
    color: $secondary;
    font-size: 15px;
    font-weight: lighter;
}

#adviceAndClearDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    min-width: 90%;
    max-width: 90%;
    min-height: 18%;
    max-height: 18%;
    margin-top: 7px;
    background-color: $primary;
    border-radius: 50px;
    border: 1px solid $accent;
    @media (max-width: 550px)
    {
        min-height: 50%;
        max-height: 50%;
        padding: 5px;
    }
    @media (min-width: 625px) and (max-width: 975px)
    {
        min-height: 50%;
        max-height: 50%;
        padding: 5px;
    }
}

#clear {
    font-size: 15px;
    min-width: 18%;
    max-width: 18%;
    margin: 0 auto;
    margin-top:7px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    @media (max-width: 550px)
    {
        min-width: 30%;
        max-width: 30%;
        font-size: 12px;
        min-height: 25%;
        max-height: 25%;
        padding: 2px;
    }
    @media (max-width: 1100px) and (max-height: 600px)
    {
        min-height: 40%;
        max-height: 40%;
        font-size: 12px;
    }
}

#clear:active {
    @media (max-width: 500px) {
        background-color: $tertiary;
        color: $primary;
    }
}

#clear:hover {
    @media (min-width: 500px) {
        background-color: $tertiary;
        color: $primary;
    }
}

#flexTab {
    min-width: 13%;
}

#ovrTab {
    background-color: $accent;
}

#printPdfButton {
    min-width: 15%;
    max-width: 15%;
    min-height: 30%;
    max-height: 30%;
    text-align: center;
    margin: 0 auto;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1150px) and (min-height: 700px)
    {
        font-size: 10px;
    }
    @media (max-width: 550px)
    {
        min-width: 50%;
        max-width: 50%;
    }
}

.tab {
    padding: 10px;
    margin: 0 auto;
    cursor: pointer;
    min-width: 5%;
    font-size: 22px;
    text-align: center;
    background-color: $primary;
    border: 1px solid $tertiary;
    border-bottom: 1px solid $primary;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    box-shadow: 0.5px 0.5px 0.5px 0.5px $secondary, 0.5px 0.5px 0.5px 0.5px $tertiary;
    @media (max-width: 1000px)
    {
        font-size: 18px;
    }
    @media (max-width: 800px)
    {
        font-size: 12px;
    }
}

.tab:not(:last-of-type) {
    margin-right:10px;
}

#tabsDiv {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    max-width: 100%;
    min-height: 82%;
    max-height: 82%;
    @media (max-width: 550px)
    {
        align-items: center;
        padding: 5px;
    }
    @media (min-width: 625px) and (max-width: 975px)
    {
        margin-top: 5px;
        align-items: center;
        padding: 5px;
    }
}

#tabRowDiv {
    display: flex;
    flex-direction: row;
    min-width: 95%;
    max-width: 95%;
    min-height: 10%;
    max-height: 10%;
}