$primary: #FFFFFF;
$secondary: #383838;
$tertiary: #384050;
$accent: #d4d1e5;

.rowDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 20px;
    @media (max-width: 625px)
    {
        flex-direction: column;
        margin-top: 0;
    }
    @media (min-width: 625px) and (max-width: 975px)
    {
        flex-direction: column;
        margin-top: 0;
    }
    min-height: 100%;
    max-height: 100%;
}

#searchBar {
    flex: 1 0 auto;
    max-width: 25%;
    min-width: 25%;
    margin: 0 auto;
    background-color: $primary;
    color: $secondary;
    border:1px solid $secondary;
    @media (max-width: 550px)
    {
        min-height:24px;
        max-height:24px;
    }
}

#searchDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-width: 50%;
    max-width: 50%;
    text-align: center;
    @media (max-width: 550px)
    {
        min-width: 90%;
        max-width: 90%;
        margin-bottom: 4px;
    }
    @media (min-width: 625px) and (max-width: 975px)
    {
        min-width: 100%;
        max-width: 100%;
    }
}

#searchTitle {
    max-width: 50%;
    min-width: 50%;
    color: $secondary;
    font-size: 22px;
    font-weight: lighter;
    margin: 4px;
    @media (max-width: 550px)
    {
        font-size: 19px;
    }
}